<template>
  <div>
        <fieldset class="p-2 mt-3 border">
            <legend class="w-auto">SEO</legend>
            <div class="row">
                <div class="text-right col-md-12">
                    <b-button class="mt-2" size="sm" @click="saveSeo(article.id)" variant="primary"><i class="fas fa-save"></i> SEO speichern</b-button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Title</label>
                        <input type="text" v-model="seo.metaTitle" class="form-control" :class="{'is-invalid': seo.errors.has('metaTitle')}" />
                        <has-error :form="seo" field="metaTitle"></has-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Keywords</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="seo.keywords" :class="{'is-invalid': seo.errors.has('keywords')}"></ckeditor>
                        <has-error :form="seo" field="keywords"></has-error>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Meta Description</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="seo.description" :class="{'is-invalid': seo.errors.has('description')}"></ckeditor>
                        <has-error :form="seo" field="description"></has-error>
                    </div>
                </div>
            </div>

        </fieldset>

        <!-- <fieldset class="p-2 mt-3 border">
            <legend class="w-auto">Google Shopping</legend>

        </fieldset>

        <fieldset class="p-2 mt-3 border">
            <legend class="w-auto">Google Shopping</legend>
            <h6>Zukünftiges Feature</h6>
        </fieldset> -->
  </div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';


export default {
    props: [
        'article',
    ],

    data() {
        return {
                seo: new window.Form({
                            'description': '',
                            'keywords': '',
                            'metaTitle': '',
                        }),
                
                editor: ClassicEditor,
                editorConfig: {
                    simpleUpload: {
                        uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer '+ this.$auth.token()
                        }
                    }
                },
        }
    },

    methods: {
        saveSeo(id)
        {
            this.$Progress.start();
            this.seo
                .post("/articles/" + id + '/updateSeo')
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Seo Daten wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created(){
        this.seo.description = this.article.description;
        this.seo.keywords = this.article.keywords;
        this.seo.metaTitle = this.article.metaTitle;
    }

}
</script>

<style>

</style>